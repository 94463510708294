import { ReactNode } from 'react';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from './numberLocaleFormat';
import { LanguageService } from 'services';
import { t } from 'i18next';
import { ICouponResDto, ITableOrderItemResDto } from '../submodules/sicpama-shared';
import { StoreResDto } from '../types';

export const renderCoupons = (order: ITableOrderItemResDto): ReactNode => {
  const { coupons = [], currency = 'KRW' } = order;
  return (
    <div className="text-md">
      {coupons.map((coupon, idx) => {
        const separator = idx !== 0 ? 'border-t border-gray-300' : '';
        const { discountedAmount } = coupon;
        return (
          <div key={coupon.id} className={`flex flex-row ${separator} pb-2`}>
            <p className="pr-2 truncate text-[#0000FF] flex-shrink min-w-0">{coupon.name}</p>
            <p className="text-[#0000FF] flex-shrink-0 ml-auto whitespace-nowrap">
              - &nbsp;
              {numberLocaleFormat(discountedAmount ?? 0, CURRENCY_TO_LOCALE_PRICE[currency])}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export function handleDynamicHTMLContent(
  template: string,
  values: Record<string, string>,
  earnedStamps = 0,
): string {
  let result = template;
  for (const [key, value] of Object.entries(values)) {
    result = result.replace(new RegExp(`__${key}__`, 'g'), value);
  }

  // Now, replace the stamp grid placeholder
  const parser = new DOMParser();
  const doc = parser.parseFromString(result, 'text/html');
  const stampGridPlaceholder = doc.getElementById('stamp-grid-placeholder');

  if (stampGridPlaceholder) {
    const stampGrid = doc.createElement('div');
    stampGrid.className = 'stamp-item-grid';
    Array.from({ length: +values.stampValue }, (_, index) => `Item ${index + 1}`).forEach(
      (item, index) => {
        const stampItem = doc.createElement('div');
        stampItem.className = 'stamp-item';
        const img = doc.createElement('img');
        img.width = 50;
        img.height = 50;
        img.src =
          index < earnedStamps
            ? 'https://cdn.sicpama.com/sicpama-assets/sicpama_colorful_stamp.png'
            : 'https://cdn.sicpama.com/sicpama-assets/sicpama_colorless_stamp.png';
        img.alt = 'stamp';
        stampItem.appendChild(img);
        stampGrid.appendChild(stampItem);
      },
    );
    stampGridPlaceholder.parentNode?.replaceChild(stampGrid, stampGridPlaceholder);
  }
  // Convert back to string
  return new XMLSerializer().serializeToString(doc.body.firstChild as Node);
}

export function getCouponDesign(data: {
  coupon: ICouponResDto;
  store: StoreResDto;
  values?: Record<string, string>;
  earnedStamps?: number;
  t?: any;
  currentNumberOfCoupons?: number;
}): string | undefined {
  const { coupon, store, values, earnedStamps = 0, currentNumberOfCoupons } = data;
  const keys = Object.keys(coupon.advert?.location.LOGIN_PAGE || {});
  if (keys.length === 0 || !coupon.advert?.location.LOGIN_PAGE) {
    return undefined;
  }

  const customerCriteria = coupon.criteria?.customer;

  const stampThreshold = (customerCriteria?.length ? customerCriteria[0]?.stamps?.count : 0) ?? 0;
  const displayStamps =
    earnedStamps % stampThreshold === 0 && earnedStamps >= stampThreshold
      ? stampThreshold
      : earnedStamps % stampThreshold;

  if (['/menus', '/customer-receipt'].includes(window.location.pathname) && values) {
    if (displayStamps === 0 && (earnedStamps >= stampThreshold || earnedStamps === 0)) {
      const numberOfCoupons = earnedStamps / stampThreshold || currentNumberOfCoupons;
      if (window.location.pathname.includes('/customer-receipt')) {
        values.heading1 = t('coupons.youHaveEarnedCoupons', {
          number: numberOfCoupons,
        });
      }
      if (window.location.pathname.includes('/menus')) {
        values.heading2 = `(${t('coupons.youHaveCouponsReadyToUse', {
          number: numberOfCoupons,
        })})`;
      }
    }
    if (displayStamps !== 0 && earnedStamps >= stampThreshold) {
      const numberOfCoupons = Math.floor(earnedStamps / stampThreshold);
      if (window.location.pathname.includes('/customer-receipt')) {
        values.heading1 = t('coupons.youHaveEarnedCoupons', {
          number: numberOfCoupons,
        });
      }
      if (window.location.pathname.includes('/menus')) {
        values.heading2 = `(${t('coupons.youHaveCouponsReadyToUse', {
          number: numberOfCoupons,
        })})`;
      }
    }
  }

  const htmlContent =
    coupon.advert.location.LOGIN_PAGE[LanguageService.getLanguage()] ||
    coupon.advert.location.LOGIN_PAGE[keys[0]];

  // NOTE: rewards only display when in advert json has __reward__
  let rewards = '';
  if (coupon.rewards?.menuRewards?.length) {
    rewards = t('common.coupon');
  } else if (coupon.rewards?.discountPercent) {
    rewards = `${coupon.rewards?.discountPercent}% ${t('common.coupon')}`;
  } else {
    rewards = `${numberLocaleFormat(
      coupon.rewards?.discountMoney ?? 0,
      CURRENCY_TO_LOCALE_PRICE[store?.currency ?? 'KRW'],
    )} ${t('common.coupon')}`;
  }

  const stampThresholdValue = coupon.criteria?.customer?.[0]?.stamps?.count ?? 0;

  if (htmlContent.includes('__')) {
    return handleDynamicHTMLContent(
      htmlContent,
      {
        ...values,
        stampValue: `${stampThresholdValue}`,
        reward: rewards,
      },
      displayStamps,
    );
  }

  return htmlContent;
}
